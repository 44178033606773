import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gap from "../elements/gap.tsx";
import Text from "../elements/text.tsx";
import ImageText from "../elements/imagetext.tsx";
import Title from "../elements/title.tsx";
import AliceCarousel from "react-alice-carousel";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Argonaut`}</h1>
    <Gap margin={200} mdxType="Gap" />
    <Text mdxType="Text">
Argonaut is a micro frontend framework with run-time integration, providing a set
of container/shell capabilities and infrastructure, that makes it faster and easier
for teams to integrate their product experiences into the www.realestate.com.au web
app.
      <br />
      <br />
I worked in the web architecture team in REA Group as a software developer for three years. The main project is in developing the micro frontend framework Argonaut. I deep dived into the architecture of frontend structures, developed the platform features with typescript, wrote documentations/guidelines, communicated with other teams on the platform features/updates.
    </Text>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      